import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { evaluationPlatformService } from "services";
import { getValueBrowserStorage } from "services/browserStorageService";
import { RootState } from "store";
import { getCandidateProfile, getDetailsForCandidatebyCandidateTrackId, setOnboardCandidateProfile } from "store/evaluationPlatform";
import styled from "styled-components";
import { CandidateExperienceExpertiseType, CandidateProfileType, ResumeData } from "types/OnboardCandidate";
import { Candidate_Id, Candidate_Track_Id, DEFAULT_TOKEN } from "utilities/constants";
import CandidateProfile from "./CandidateProfile";
import CandidateExperienceExpertise from "./ExperienceExpertise/CandidateExperienceExpertise";
import JobStepsContainer from "./JobStepsContainer";
import OnboardHeader from "./OnboardHeader";
import ResumeUpload from "./ResumeUpload";
import { getFormatLocationForSave } from "pages/ProfileReview/ProfileReview.util";

const StyledWrapper = styled.div`
    height: 100vh;

    .content-container {
        margin-top: 72px;
        height: calc(100% - 72px);
    }
`

const OnboardCandidate = () => {

    const dispatch = useDispatch();
    const candidate = useSelector((state: RootState) => state.evaluationPlatform.candidate);
    const candidateProfileData = useSelector((state: RootState) => state.evaluationPlatform.candidateProfileOnboard);
    const loadingCandidateActivity = useSelector((state: RootState) => state.evaluationPlatform.loading);

    const { enqueueSnackbar } = useSnackbar();

    const candidateId = getValueBrowserStorage(Candidate_Id) ?? "";
    const candidateTrackId = getValueBrowserStorage(Candidate_Track_Id) ?? "";

    const candidateProfileSubmitBtnRef = useRef<HTMLInputElement | null>(null);
    const candidateExpertiseSubmitBtnRef = useRef<HTMLInputElement | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [completedStep, setCompletedStep] = useState<number>(-1);

    const handleResumeSelect = (value: ResumeData) => {
        handleProfileDataChange({ ...candidateProfileData, resumeUrl: value.url });
        setLoading(true);
        evaluationPlatformService.updateCandidateResume(value.url, candidateId)
            .then((res) => {
                const intervalId = setInterval(() => {
                    evaluationPlatformService.getResumeParsingStatus(candidateId)
                        .then((res) => {
                            if (res.output.resumeParsingCompleted) {
                                clearInterval(intervalId);
                                evaluationPlatformService.getCandidateProfile(candidateId)
                                    .then(res => {
                                        handleProfileDataChange({ ...candidateProfileData, ...res.output });
                                        setCurrentStep(prev => prev + 1);
                                        setLoading(false);
                                    })
                                    .catch((e) => {
                                        enqueueSnackbar('Error occurred while parsing the resume please try again!', { variant: 'error', autoHideDuration: 2500 });
                                        setLoading(false);
                                    })
                            }
                        })
                        .catch((e) => {
                            enqueueSnackbar('Error occurred while parsing the resume please try again!', { variant: 'error', autoHideDuration: 2500 });
                            setLoading(false);
                        })
                }, 5000);
            })
            .catch((e) => {
                enqueueSnackbar('Error occurred while parsing the resume please try again!', { variant: 'error', autoHideDuration: 2500 });
                setLoading(false);
            })
    }

    const handleNextClick = () => {
        if (currentStep === 1) {
            candidateProfileSubmitBtnRef.current?.click();
        } else if (currentStep === 2) {
            candidateExpertiseSubmitBtnRef.current?.click();
        }
    }

    const handlePrevClick = () => {
        setCurrentStep((prev) => prev - 1);
    }

    const handleSubmitCandidateProfile = (value: CandidateProfileType) => {
        handleProfileDataChange({
            ...candidateProfileData,
            ...value,
        });
        saveCurrentStepDetails({
            ...candidateProfileData,
            ...value,
        }, () => {
            setCompletedStep((prev) => Math.max(currentStep, prev));
            setCurrentStep((prev) => prev + 1);
        })
    }

    const handleSubmitExp = (value: CandidateExperienceExpertiseType) => {
        handleProfileDataChange({
            ...candidateProfileData,
            ...value,
        })
        saveCurrentStepDetails({
            ...candidateProfileData,
            ...value,
        }, () => {
            setCompletedStep((prev) => Math.max(currentStep, prev));
            setCurrentStep((prev) => prev + 1);
        })
    }

    const handleProfileDataChange = (value: CandidateProfileType | CandidateExperienceExpertiseType) => {
        dispatch(setOnboardCandidateProfile({ ...candidateProfileData, ...value }));
    }

    const saveCurrentStepDetails = (value: CandidateProfileType, cb: Function) => {
        setLoading(true);
        const candidateProfile: CandidateProfileType = {
            ...candidateProfileData,
            ...value,
            candidateId,
        }
        candidateProfile['locations'] = getFormatLocationForSave(candidateProfile.selectedLocations);
        evaluationPlatformService.updateCandidateProfilePlacement(candidateProfile)
            .then((res) => {
                cb();
            })
            .catch(() => {
                enqueueSnackbar('Error occurred while saving your profile. please try again!', { variant: 'error', autoHideDuration: 2500 });
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        if (!candidate && candidateTrackId) {
            dispatch(getDetailsForCandidatebyCandidateTrackId({
                token: DEFAULT_TOKEN,
                candidateTrackId: candidateTrackId,
            }))
        }
    }, [candidate])

    useEffect(() => {
        dispatch(getCandidateProfile(candidateId));
    }, [])

    return (
        <StyledWrapper>
            <OverlayLoader loading={loadingCandidateActivity || (loading && currentStep !== 0)} disableOverlay={true} />
            <OnboardHeader
                profileImgUrl={candidateProfileData.profileImgUrl}
                name={candidateProfileData.fullname}
                email={candidateProfileData.email}
            />
            <div className="content-container">
                {currentStep === 0 && <ResumeUpload
                    handleSubmit={handleResumeSelect}
                    loading={loading}
                />}
                {
                    currentStep > 0 && <JobStepsContainer
                        handleNextClick={handleNextClick}
                        handlePrevClick={handlePrevClick}
                        currentStep={currentStep}
                        completedStep={completedStep}
                    >
                        {currentStep === 1 && <CandidateProfile
                            handleSubmit={handleSubmitCandidateProfile}
                            candidateProfileSubmitBtnRef={candidateProfileSubmitBtnRef}
                            initialValue={candidateProfileData}
                            handleFormDataChange={handleProfileDataChange}
                        />}
                        {currentStep === 2 && <CandidateExperienceExpertise
                            initialValue={{
                                experiences: candidateProfileData.experiences,
                                expertises: candidateProfileData.expertises,
                                certifications: candidateProfileData.certifications,
                                licenses: candidateProfileData.licenses,
                            }}
                            handleSubmit={handleSubmitExp}
                            candidateExpertiseSubmitBtnRef={candidateExpertiseSubmitBtnRef}
                            handleFormDataChange={handleProfileDataChange}
                        />}
                    </JobStepsContainer>
                }
            </div>
        </StyledWrapper>
    )
}

export default OnboardCandidate;