import React from "react";
import styled from "styled-components";
import { FilePerson } from '@styled-icons/bootstrap/FilePerson';    // resume
import { AssignmentInd } from '@styled-icons/material-twotone/AssignmentInd'        // build profile
import { BadgeCheck } from '@styled-icons/boxicons-regular/BadgeCheck'; // exp
import { Microphone } from '@styled-icons/boxicons-regular/Microphone';
import { CheckCircleFill } from '@styled-icons/bootstrap/CheckCircleFill';
import { StyledIcon } from "styled-icons/types";
import { Button } from "@material-ui/core";
import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt'
import { RightArrowAlt } from '@styled-icons/boxicons-regular/RightArrowAlt'
import { useSelector } from "react-redux";
import { RootState } from "store";

const StyledWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 1rem;
    gap: 0.5rem;

    .steps-container {
        height: 23%;
    }
    .steps-form-container {
        height: 70%;
        padding: 1rem 2rem 1rem 2rem;
        overflow: auto;
    }
    .steps-form-container > div {
        width: 100%;
        max-width: 100rem;
        margin: auto;
    }
    .styled-box-shadow {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .job-title-container {
        padding: 1rem 2rem 1rem 2rem;
        border-bottom: 1px solid #bfd1ff;
    }
    .job-steps-container {
        display: flex;
        height: 60%;
    }
    .job-steps-container div {
        flex-basis: 25%;
    }
`

const JobStepsContainer = (props: {
    currentStep: number;
    completedStep: number;
    children: React.ReactNode;
    handleNextClick: () => void;
    handlePrevClick: () => void;
}) => {

    const currentTrack = useSelector((state: RootState) => state.evaluationPlatform.currentTrack?.candidateTrack?.[0]);

    return (
        <StyledWrapper>
            <div className="steps-container styled-box-shadow">
                <div className="job-title-container text-left">
                    <h3>{currentTrack?.title}</h3>
                </div>
                <div className="job-steps-container">
                    <StepViewer
                        selected={props.currentStep === 0}
                        title="Resume_JD.pdf"
                        stepName={'Step 1'}
                        completed={props.completedStep >= 0}
                        icon={FilePerson}
                    />
                    <StepViewer
                        selected={props.currentStep === 1}
                        title="Build your Profile"
                        stepName={'Step 2'}
                        completed={props.completedStep >= 1}
                        icon={AssignmentInd}
                    />
                    <StepViewer
                        selected={props.currentStep === 2}
                        title="Add your Experience & Expertise"
                        stepName={'Step 3'}
                        completed={props.completedStep >= 2}
                        icon={BadgeCheck}
                    />
                    <StepViewer
                        selected={props.currentStep === 3}
                        title="Make a Pitch"
                        stepName={'Step 4'}
                        completed={props.completedStep >= 3}
                        icon={Microphone}
                    />
                </div>
            </div>
            <div className="steps-form-container styled-box-shadow">
                <div>
                    {props.children}
                </div>
            </div>
            <div className="px-4 d-flex align-items-center justify-content-end">
                <Button
                    variant="outlined"
                    color="primary"
                    className="mr-4"
                    type={"button"}
                    onClick={props.handlePrevClick}>
                    <LeftArrowAlt width={'25px'} className="mr-2" />
                    Previous
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={props.handleNextClick}>
                    Next
                    <RightArrowAlt width={'25px'} className="ml-2" />
                </Button>
            </div>
        </StyledWrapper>
    )
}

const StyledStepsWrapper = styled.div<{ selected: boolean }>`
    .step-name-style {
        display: inline-block;
        margin-left: 0.2rem;
    }
    padding: 1rem 2rem 1rem 2rem !important;
    background: ${props => props.selected ? "#eaefff" : '#ffffff'};
`
const StepViewer = (props: {
    selected: boolean;
    title: string;
    completed: boolean;
    icon: StyledIcon;
    stepName: string;
}) => {
    return (
        <StyledStepsWrapper className="text-left" selected={props.selected}>
            <span className="text-muted step-name-style">{props.stepName}</span>
            <div className="mt-2 d-flex align-items-center justify-content-between">
                <div className="flex-grow-1">
                    <props.icon width={'30px'} color="#325cd5" />
                    <span className="ml-2">{props.title}</span>
                </div>
                <CheckCircleFill width={'15px'} color="#2bba2f" style={{ flexBasis: '15px' }} />
            </div>
        </StyledStepsWrapper>
    )
}

export default JobStepsContainer;