import { IconButton } from "@material-ui/core";
import { FileEarmarkText } from "@styled-icons/bootstrap/FileEarmarkText";
import { Upload } from "@styled-icons/boxicons-regular/Upload";
import axios from "axios";
import { OverlayLoader } from "components/Common/Loader/loaderWithOverlay";
import { round } from "lodash";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { evaluationPlatformService } from "services";
import { getValueBrowserStorage } from "services/browserStorageService";
import styled from "styled-components";
import { FileInformation } from "types/OnboardCandidate";
import { convertHttpsToHttpFromUrl } from "utilities/commonUtils";
import { Candidate_Track_Id } from "utilities/constants";

const StyledContainer = styled.div`
    .upload-icon-container {
        display: flex;
        justify-content: start;
        gap: 1rem;
    }
    .help-icon {
        border-radius: 20%;
        border: 1px solid #325cd5;
    }
    .help-icon svg {
        color: #325cd5;
    }
    .no-pointer-event {
        pointer-events: none;
    }
`

const FilesUpload = (props: {
    files: FileInformation[],
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    maxFileSizeInBytes: number;
    fieldName: string,
    directory: string,
}) => {

    const { enqueueSnackbar } = useSnackbar();
    const inputEleRef = useRef<HTMLInputElement | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const candidateTrackId = getValueBrowserStorage(Candidate_Track_Id) ?? "";

    const handleUpload = () => {
        inputEleRef.current?.click();
    }

    const validateFileSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        event.target.value = '';
        if (file?.size && file.size > props.maxFileSizeInBytes) {
            enqueueSnackbar('File size should be less then 5MB', { variant: 'error', autoHideDuration: 2500 });
            return;
        }
        uploadFile(file);
    }

    const uploadFile = (file: File | undefined) => {
        if (!file) return
        setLoading(true);
        if (file) {
            const newFileName = file?.name?.trim();
            const reader = new FileReader();

            const handleActionAfterUpload = (err?: any) => {
                if (err) {
                    setLoading(false);
                    alert('Failed with error: ' + err);
                    enqueueSnackbar('Failed with error: ' + err, { variant: 'success', autoHideDuration: 2500 });
                    return;
                }
                const location = props.directory + '/' + file.name;
                props.setFieldValue(props.fieldName, [...props.files, {
                    name: file?.name,
                    size: file?.size,
                    url: location
                }])
                setLoading(false);
            }

            reader.onloadend = () => {
                const buffer = Buffer.from(reader.result as any);

                // Getting SignedInCredentials
                evaluationPlatformService.getS3SignedInCredentials({
                    path: `${props.directory}/${newFileName}`, candidateTrackId
                }).then((res) => {
                    let bucketUrl = res.output.url;
                    // Uploading to bucket
                    axios.put(bucketUrl, buffer)
                        .then((res) => {
                            handleActionAfterUpload();
                        }).catch(err => {
                            console.log(err);
                            axios.put(convertHttpsToHttpFromUrl(bucketUrl), buffer)
                                .then((res) => {
                                    handleActionAfterUpload();
                                }).catch(err => {
                                    handleActionAfterUpload(err);
                                })
                        })
                }).catch(err => {
                    handleActionAfterUpload(err);
                });
            };

            reader.readAsArrayBuffer(file);
        }
    };

    return (
        <StyledContainer>
            <OverlayLoader loading={loading} disableOverlay={true} />
            {props.files?.map((file, idx) => {
                return (
                    <div className="upload-icon-container mb-2" key={idx + file.name}>
                        <IconButton onClick={() => { }} className="help-icon no-pointer-event">
                            <FileEarmarkText width={'1rem'} />
                        </IconButton>
                        <div className="text-left">
                            <span className="d-block small text-muted">{"License"}</span>
                            <span className="text-muted">{file.name}</span><span className="text-muted">{` | File Size: ${round(file.size / (1024 * 1024), 2)} MB`}</span>
                        </div>
                    </div>
                )
            })}
            <div className="upload-icon-container mb-2">
                <IconButton onClick={handleUpload} className="help-icon">
                    <Upload width={'1rem'} />
                </IconButton>
                <div className="text-left">
                    <span className="d-block small text-muted">{"Upload License"}</span>
                    <span className="text-muted">Format: pdf | File Size: Up to {round(props.maxFileSizeInBytes / (1024 * 1024), 2)} MB</span>
                </div>
            </div>
            <input type="file" ref={inputEleRef} onChange={(e) => validateFileSize(e)} hidden accept=".pdf" />
        </StyledContainer>
    )
}

export default FilesUpload;