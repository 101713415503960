import { MainHeader, Sidebar } from 'containers';
import SplitPaneWrapper from 'containers/QuestionCodingPane/SplitPaneWrapper';
import { getAnswerFeedbackStatus } from 'containers/QuestionCodingPane/utils/utils';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import { CareerConsultancy } from 'pages/CareerConsultancy';
import { Classes } from 'pages/Classes';
import { HiringEcosystem } from 'pages/HiringEcosystem';
import queryString from 'query-string';
import React, { Suspense, lazy, useEffect, useMemo } from 'react';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useLocation } from 'react-router';
import { isCandidateAuthenticated } from 'services/browserStorageService';
import { RootState } from 'store';
import { getMarketInfoAction, getMarketListAction, setMaximizeContent } from 'store/evaluationPlatform';
import { IGetAnswerStatusRequest, IQuestionAnswerRequest } from 'types';
import { Content_Height, DEFAULT_MARKET_NAME, DEFAULT_TOKEN } from 'utilities/constants';
import { isOpenedFromLandingPages, setStorageFromLangingPageParams } from 'utilities/landingPageUtil';
import { PrivateRoute } from 'utilities/routers/PrivateRoute';
import { PublicRoute } from 'utilities/routers/PublicRoute';
import './App.css';
import {
  DashboardPage, ForgotPasswordPage, LoginPage, NotFoundPage, ResetPasswordPage, SettingsPage, SignupPage, TrackPage, UserProfile
} from './pages';
import { ActiveElementProvider } from 'components/HigherOrderComponents/withSecurityFeatures';
import OnboardCandidate from 'pages/OnboardCandidate';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import AuthPage from 'containers/AuthPage';

const App = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isMaximizeContent } = useSelector((state: RootState) => state.evaluationPlatform);
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const _queryParams: any = queryString.parse(search);
  const capability = get(props.currentTrack, 'candidateTrack[0].capabilities');
  const handleMaximizeContent = () => dispatch(setMaximizeContent(!isMaximizeContent));

  const isAuth = () => {
    return isCandidateAuthenticated();
  }

  function updateAnswerStatus() {
    if (!capability || !capability.length) { return; }
    let questionAnswerRequest: IQuestionAnswerRequest[] = [];
    capability?.forEach((capibility: any) => {
      capibility?.questions?.forEach((question: any) => {
        question?.answers.forEach((answer: any) => {
          if (answer?.feedbacks.length > 0 && (
            answer?.feedbacks[0]?.feedbackStatus === 'RESPONSE_IS_SUBMITTED_TO_EXPERT' ||
            answer?.feedbacks[0]?.feedbackStatus === 'EXPERT_REVIEWING_RESPONSE')) {
            let req: IQuestionAnswerRequest = {
              questionId: answer?.answer.questionId,
              questionAnswerId: answer?.answer._id,
              clientFeedbackStatus: answer?.feedbacks[0]?.feedbackStatus,
              clientQuestionAnswerStatus: getAnswerFeedbackStatus(answer),
            };
            questionAnswerRequest.push(req);
          }
        });
      });
    });

    if (questionAnswerRequest?.length !== 0) {
      let payload: IGetAnswerStatusRequest = {
        token: DEFAULT_TOKEN,
        questionAnswerRequest: questionAnswerRequest,
      };
      props
        .getAnswerStatus(payload)
        .then((res: any) => {
          if (res.payload?.apiStatus === 'SUCCESS') {
            props.setAllFeedbacks(res);
          } else {
            enqueueSnackbar(res.error?.message, { variant: 'error', autoHideDuration: 2500 });
          }
        })
        .catch((err: any) => {
          enqueueSnackbar(err?.message, {
            variant: 'error',
            autoHideDuration: 2500,
          });
        });
    }
  }

  useEffect(() => {
    toast.configure();
    dispatch(getMarketInfoAction({ token: DEFAULT_TOKEN, market: DEFAULT_MARKET_NAME }));
    dispatch(getMarketListAction({ types: ['Market'] }));

    // Move user to sigup page if link was opened from landing page
    setStorageFromLangingPageParams(_queryParams);
    // eslint-disable-next-line
  }, []);

  const CommunityPage = lazy(() => import('pages/CommunityPage'));
  const TalkToExpertPage = lazy(() => import('pages/TalkToExpert'));
  const ResumeReviewPage = lazy(() => import('pages/ProfileReview'));
  const QuestionPage = lazy(() => import('pages/QuestionPage'));
  const UnsubscribeEmail = lazy(() => import('pages/UnsubscribeEmail'));

  const renderRoutes = useMemo(() => {
    return <Suspense fallback={<p>Loading...</p>}>
      <Switch>
        <PublicRoute path="/" component={LoginPage} exact />
        <PublicRoute path="/login" component={LoginPage} exact />
        <PublicRoute path="/signup" component={SignupPage} exact />

        <PublicRoute path="/placement-auth" component={AuthPage} exact />

        <PublicRoute path="/forgot-password" component={ForgotPasswordPage} exact />
        <PublicRoute path="/resetPassword" component={ResetPasswordPage} exact />
        <PublicRoute path="/linkedInAuthenticate" component={LinkedInPopUp} exact />
        <PublicRoute path="/unsubscribe" component={UnsubscribeEmail} />
        <PrivateRoute path="/meetings" component={TalkToExpertPage} exact />
        <PrivateRoute path="/consultancy" component={CareerConsultancy} exact />
        <PrivateRoute path="/settings" component={SettingsPage} />
        <PrivateRoute path="/dashboard" component={DashboardPage} />
        <PrivateRoute path="/question" component={QuestionPage} exact />
        <PrivateRoute path="/tracks" component={TrackPage} exact />
        <PrivateRoute path="/community" component={CommunityPage} exact />
        <PrivateRoute path="/profile-review" component={ResumeReviewPage} exact />
        <PrivateRoute path="/hiring-ecosystem" component={HiringEcosystem} exact />
        <PrivateRoute path="/classes" component={Classes} exact />
        <PrivateRoute path="/profile" component={UserProfile} exact />
        <PrivateRoute path="/onboard-candidate" component={OnboardCandidate} />
        <PublicRoute component={NotFoundPage} />
      </Switch>
    </Suspense>
  }, [])

  return (
    <div className="App">
      {
        isOpenedFromLandingPages() ? <>{renderRoutes}</> :
          <SplitPaneWrapper direction='horizontal' draggable={false} initialPaneSize='57px'>
            <MainHeader
              isMaximizeContent={isMaximizeContent}
              handleMaximizeContent={handleMaximizeContent}
              color="#315cd5"
              updateNotificationStatus={updateAnswerStatus}
            // donotGetCandidateInfo={true}
            />
            <div>
              <SplitPaneWrapper hideFirstPane={!isAuth() || isMaximizeContent} height={Content_Height} direction='vertical' draggable={false} initialPaneSize="95px">
                <Sidebar isMaximizeContent={isMaximizeContent} />
                <ActiveElementProvider>
                  {renderRoutes}
                </ActiveElementProvider>
              </SplitPaneWrapper>
            </div>
          </SplitPaneWrapper>
      }
    </div>
  );
};

export default App
